<template>
  <div id="app">
    <main role="main">
      <router-view />
    </main>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SocketioService from './services/socketio.service.js';

export default {
  created() {
    SocketioService.setupSocketConnection();
    this.$store.dispatch('getUserData');
  },
  beforeUnmount() {
    SocketioService.disconnect();
  },
  computed: {
    ...mapGetters("auth", ["user"])
  },
  mounted() {
    try {

    if (localStorage.getItem("authToken")) {
      this.getUserData();
    }
  } catch (error) {
    console.error('Error in mounted hook:', error);
  }
  },

  methods: {
    ...mapActions("auth", ["sendLogoutRequest", "getUserData"]),

    logout() {
      this.sendLogoutRequest();
      this.$router.push("/");
    }
  }
};
</script>
